
<style lang="scss">
#salary-head-employee {
  .color-basic {
    color: rgb(103 103 103);
  }
  .color-abt {
    color: rgb(17 85 242);
  }
  .color-atg {
    color: rgb(242, 197, 17);
  }
  .color-atb {
    color: rgb(102 242 17);
  }
  .custom-select {
    width: 25%;
    margin-left: 20px;
  }
  .line-height-40 {
    line-height: 40px;
  }
  .ledgend {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
      padding: 5px 8px 5px 8px;
    }
  }
  table {
    tr {
      border: 0.5px solid rgb(187, 187, 187);

      th {
        border: 0.5px solid rgb(187, 187, 187);
      }

      td {
        border: 0.5px solid rgb(187, 187, 187);
      }
    }
  }
}
</style>
<template>
  <div id="salary-head-employee">
    <div class="row">
      <div class="col-md-12" id="whole">
        <div class="card gutter-b">
          <div class="card-header">
            <h5 style="line-height: 33px; margin-bottom: 0">
              Employee's Salary Heads Setup
            </h5>

            <select
              v-if="!loader"
              class="mb-3 mr-sm-3 mb-sm-0 custom-select"
              v-model="userId_"
              @change="sendTothisUser()"
            >
              <option
                v-for="(empItem, index) in getActiveEmployee"
                :key="index"
                :value="empItem.id"
              >
                {{ empItem.full_name }}
              </option>
            </select>

            <div class="close">
              <router-link :to="{ name: 'payroll.salary.heads' }">
                <i class="fa fa-times"></i>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div
                  class="v-data-table v-data-table--fixed-header theme--light"
                >
                  <div class="v-data-table__wrapper">
                    <table class="table table-hover">
                      <thead>
                        <tr style="background-color: rgb(242, 244, 245)">
                          <th width="30%">Earning Heads</th>
                          <th width="20%">Amount</th>
                          <th width="30%">Deduction Heads</th>
                          <th width="20%">Amount</th>
                        </tr>
                        <tr v-if="loader">
                          <td colspan="4" class="text-center">
                            <data-loader></data-loader>
                          </td>
                        </tr>
                      </thead>
                      <tbody v-if="!loader">
                        <tr
                          v-for="(item, index) in getSalaryHeadsDetails"
                          :key="index"
                        >
                          <td class="line-height-40">
                            <span style="float: left">{{
                              item.e.earning_heads
                            }}</span>
                            <span style="float: right"
                              ><i
                                class="fa fa-circle color-basic"
                                style="font-size: 10px"
                                v-if="item.e.is_basic == '1'"
                              ></i
                            ></span>
                            <span style="float: right"
                              ><i
                                class="fa fa-circle color-abt"
                                style="font-size: 10px; margin-right: 2px"
                                v-if="item.e.applicable_after_tax == '1'"
                              ></i
                            ></span>
                          </td>
                          <td>
                            <input
                              v-if="item.e.flag"
                              type="number"
                              class="form-control"
                              :value="item.e.amount"
                            />
                          </td>

                          <td class="line-height-40">
                            <span style="float: left">{{
                              item.d.deduction_heads
                            }}</span>
                            <span style="float: right"
                              ><i
                                class="fa fa-circle color-abt"
                                style="font-size: 10px"
                                v-if="item.d.applicable_after_tax == '1'"
                              ></i
                            ></span>
                            <span style="float: right"
                              ><i
                                class="fa fa-circle color-atb"
                                style="font-size: 10px; margin-right: 2px"
                                v-if="item.d.applicable_to_basic_gross == '0'"
                              ></i
                            ></span>
                            <span style="float: right"
                              ><i
                                class="fa fa-circle color-atg"
                                style="font-size: 10px; margin-right: 2px"
                                v-if="item.d.applicable_to_basic_gross == '1'"
                              ></i
                            ></span>
                          </td>
                          <td>
                            <input
                              v-if="item.d.flag"
                              type="number"
                              class="form-control"
                              :value="item.d.amount"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot v-if="!loader">
                        <tr>
                          <td colspan="4">
                            <div style="text-align: left; float: left">
                              <ul class="ledgend">
                                <li>
                                  <i
                                    class="fa fa-square color-basic"
                                    aria-hidden="true"
                                  ></i>
                                  Basic
                                </li>
                                <li>
                                  <i
                                    class="fa fa-square color-atg"
                                    aria-hidden="true"
                                  ></i>
                                  Applicable to gross
                                </li>
                                <li>
                                  <i
                                    class="fa fa-square color-atb"
                                    aria-hidden="true"
                                  ></i>
                                  Applicable to basic
                                </li>
                                <li>
                                  <i
                                    class="fa fa-square color-abt"
                                    aria-hidden="true"
                                  ></i>
                                  Applicable after tax
                                </li>
                              </ul>
                            </div>
                            <div style="text-align: right; float: right">
                              <button class="btn btn-success btn-sm">
                                <i class="fa fa-edit"></i>Update
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#whole {
  .card {
    .card-header {
      padding: 16px !important;
      display: inline-flex;
      .close {
        border-left: 1px solid #ccc;
        height: 58px;
        width: 58px;
        /* background: black; */
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        i {
          line-height: 58px;
          font-size: 25px;

          color: #ccc;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_EMPLOYEE_SALARY_HEADS_DETAILS,
  FETCH_ACTIVE_EMPLOYEE,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import dataLoader from "@/includes/content/DataLoader";
export default {
  data() {
    return {
      errors: [],
      loader: false,
      userId_: "",
    };
  },
  components: {
    "data-loader": dataLoader,
  },

  mounted() {
    this.loader = true;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Salary Heads", route: "/payroll/salary-heads" },
      { title: "Employee's Salary Heads Setup" },
    ]);
    var userId = this.$route.params.employee_id;
    this.userId_ = userId;
    this.$store.dispatch(FETCH_ACTIVE_EMPLOYEE);
    this.fetchdata(userId);
  },
  methods: {
    fetchdata(userId) {
      this.loader = true;
      this.$store
        .dispatch(FETCH_EMPLOYEE_SALARY_HEADS_DETAILS, {
          user_id: userId,
        })
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    onSubmit() {},
    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append,
      });
    },
    sendTothisUser() {
      this.fetchdata(this.userId_);
      this.$router.push({
        name: "payroll.salary.heads.employee",
        params: {
          employee_id: this.userId_,
        },
      });
    },
  },

  computed: {
    ...mapGetters(["getSalaryHeadsDetails", "getActiveEmployee"]),
  },
};
</script>
